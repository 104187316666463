<template>
    <div class="dynamically">
        <div class="shdt_dynamically">
            <div class="shdt_dynamically_son" v-for="(item,key) in press_data" :key="key" @click="skip('/dynamicallyDetails',item)">
            <img :src=item.list_img alt="">
            <div class="shdt_dynamically_son_text">
                <div class="shdt_dynamically_son_text_introduce">{{item.list_text}}</div>
                <div class="shdt_dynamically_son_text_bottom">
                    <div>{{item.list_firmName}}</div>
                    <div>{{item.list_date}}</div>
                </div>
            </div>
        </div>

    </div>
    </div>
</template>

<script>
import {
  defineComponent,reactive,store
} from "@/plugin/importCommonUse";
import { useRouter } from "vue-router";
import moduleHead from "@/components/moduleHead/index.vue"
export default defineComponent({
    components:{
        moduleHead
    },
    setup() {
        let router = useRouter();
        let data = reactive({
            name:"",//参会代表人姓名
            companyName:"",//参会企业单位名称
            contactWay:"",//参会联系方式
            theGroups:"",//所属团体
            education:"",//学历
            politicsStatus:"",//政治面貌
            socialTitle:"",//社会职务
            nativePlace:"",//籍贯
            individualResume:""//个人简介
        })
        let skip = (url,data)=>{
            if(data){
                localStorage.setItem('list_detailsHTML',data['list_detailsHTML'])
            }
            router.push(url);
        }
        let press_data = store.state.press_data;
        return {
            skip,data,press_data
        };
    },
})
</script>
<style>
.dynamically{
    padding: 0 10px;
}
.shdt_dynamically_dynamically{
        margin: 0px 8px;
    }
    .shdt_dynamically_son{
        width: 100%;
        display: flex;
        margin: 0px 0 25px 0; 
    }
    .shdt_dynamically_son img{
        width: 33%;
        height: 71px;
    }
    .shdt_dynamically_son_text{
        width: 66%;
        height: 70px;
        /* height: 100px; */
        margin-left: 1%;
        border-bottom: 1px #9A9A9A solid;
    }
    .shdt_dynamically_son_text_introduce{
        letter-spacing:1.7px;
        font-size: 14px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 1.5;					
        -webkit-box-orient: vertical;
    }
    .shdt_dynamically_son_text_bottom{
        display: flex;
        font-size: 12px;
        color: #9A9A9A;
        justify-content: space-between;
        margin-top: 20px;
        margin-left: 6px;
        margin: 10px 0px 20px 0px; 
    }
    .shdt{
        margin: 10px 8px;
    }
</style>
